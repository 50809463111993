<template>
  <v-container fluid style="display: flex;  height: 100vh; flex-direction: column">
    <v-row dense>
      <!-- Reports -->
      <v-col  cols="12" md="6">
        <v-card height="42vh"  elevation="5">
          <div class="d-flex align-center elevation-3 py-1" >
            <v-img class="ml-1" :src="organizationsBlack" max-height="21" max-width="22"></v-img>
            <div class="text-left font-weight-bold font-size15 ml-1 ">{{ $t('organizations') }}</div>
          </div>
          <v-divider></v-divider>
          <div>
            <v-data-table
                :no-data-text="$t('no-data-available')"
                :loading-text="$t('loading')"
                :items="billingReportForDataTable"
                :headers="medicalInstitutionHeaders"
                item-key="medicalInstitutionId"
                fixed-header
                dense
                :loading="loader"
                :search="appBarSearch"
                ref="table"
                class="mx-0 px-0 medical-staff-data-table pointer"
                style="display: flex; flex-direction: column;"
                :height="this.$vuetify.breakpoint.width <= 1336 ? '37.7vh' : '38.7vh'"
                max-height="380"
                group-by="medicalInstitutionId"
                show-expand
                hide-default-footer
                :single-expand="true"
                :footer-props="footerProps"
                :items-per-page="-1"
            >
              <template  v-slot:[`group.header`]="{ headers, items, toggle,isOpen, group}">
                <td @click="toggle" class="elevation-1 px-1 py-0 white-background" :colspan="12" style=" height:25px !important;">
                  <v-row class="px-0" dense @click="selectedOrganization(items[0], isOpen)">
                    <v-col cols="1" class="float-left mr-2">
                      <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">
                        <v-icon small v-if="isOpen">mdi-arrow-down</v-icon>
                        <v-icon v-else small>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="d-flex text-left py-0 ml-n5" >
                      <span class="font-weight-bold ml-1 font-size12 mt-3" v-if="items && items.length > 0">
                        {{items[0].medicalInstitutionName}}
                      </span>
                    </v-col>
                    <v-col>
                      <span class="float-right mr-4">
                      {{ getLastInvoiceAmount(items[0].invoiceReadDtoForReporting) }}
                        </span>
                    </v-col>

                  </v-row>
                </td>
              </template>

              <template v-slot:item="{ item, index }">
                <tr v-if="index === 0" >
                  <td class="color-header text-left" v-for="(header, index) in insideHeaders" :key="index" :colspan="6" style=" height:20px !important;">
                    <span class="font-weight-medium font-size11">{{ header.text }}</span>
                  </td>
                </tr>

                <tr v-for="hospital in item.hospitalsList" :key="hospital.hospitalId" @click="handleFacilityClick(hospital)">
                  <td class="text-left font-size12 font-weight-medium" :colspan="6" style=" height:25px !important;">
                    {{ hospital.hospitalName }}
                  </td>
                  <td :colspan="6" style=" height:25px !important;">
                    <v-tooltip bottom color="primary2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="blue"
                            class="float-right mr-n2 mt-1"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <span>{{$t('view-facility-details')}}</span>
                    </v-tooltip>
                  </td>
                </tr>

              </template>
            </v-data-table>
          </div>
        </v-card>
        <v-card elevation="5" class="overflow-hidden mt-n3" height="42vh">
          <div class="d-flex align-center elevation-3 py-1" >
            <v-img class="ml-1" :src="infoBlack" max-height="21" max-width="22"></v-img>
            <div class="text-left font-weight-bold font-size15 ml-1 ">{{$t('wellness-revenue')}}</div>
          </div>
          <v-divider></v-divider>
          <v-card-text >
<!--            <v-row>-->
<!--              <v-col cols="6">-->
<!--                <v-data-table-->
<!--                    :headers="headers"-->
<!--                    :items="invoicesDataOrganization"-->
<!--                    class="elevation-3 mb-3"-->
<!--                    :items-per-page="-1"-->
<!--                    height="340"-->
<!--                >-->
<!--                  <template v-slot:item="{ item }">-->
<!--                    <tr>-->
<!--                      <td class="text-left font-weight-medium font-size12" style="height:10px;">-->
<!--                        {{ formatYearMonth(item.invoiceYear, item.invoiceMonth) }}-->
<!--                      </td>-->
<!--                      <td class="text-left font-weight-medium font-size12" style="height:10px;">-->
<!--                        {{ item.invoiceNumber }}-->
<!--                      </td>-->
<!--                      <td class="text-right pr-4 font-weight-medium font-size12" style="height:10px;">-->
<!--                        {{ formatCurrency(item.grandTotal) }}-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                  </template>-->
<!--                </v-data-table>-->
<!--              </v-col>-->
<!--              <v-col cols="6">-->
            <div style="display: flex; flex-direction: column;">
                <Graph ref="graphWellnessmetric" type="area" height="280vh" :options="chartOptionsWellnessmetric" :series="graphSeriesWellnessmetric"></Graph>
            </div>
<!--              </v-col>-->
<!--            </v-row>-->

          </v-card-text>
        </v-card>
      </v-col>
      <!-- Analytics -->
      <v-col  cols="12" md="6">
        <v-card elevation="5" class="overflow-auto" height="42vh">
          <div class="d-flex align-center elevation-3 py-1" >
            <v-img class="ml-1" :src="billingsNew" max-height="21" max-width="22"></v-img>
            <div class="text-left font-weight-bold font-size15 ml-1 ">{{ $t('facility-billings') }} : {{ hospitalName }}</div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
            <div class="d-flex justify-space-between px-2">
              <div class="d-flex align-center">
              <!--              <v-progress-circular-->
              <!--                  v-if="facilityClicked"-->
              <!--                  class="font-size12 mx-2 px-2 font-weight-bold"-->
              <!--                  :rotate="-90"-->
              <!--                  :size="85"-->
              <!--                  :width="3"-->
              <!--                  :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99453 : ''"-->
              <!--                  :color="getHospitalSummary(clickedFacilityId)?.percent99453 >= 100 ? 'green' : 'primary'"-->
              <!--              >-->
              <!--                99453 {{ getHospitalSummary(this.clickedFacilityId)?.percent99453 || '0' }}%-->
              <!--              </v-progress-circular>-->
              <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                {{ $t('active') }}
                <v-avatar
                    color="#09CB09"
                    size="40">
                  <span class="white--text">{{ facilityUsersCounters.active }}</span>
                </v-avatar>
              </div>
              <!--              INACTIVE-->
              <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                {{ $t('inactive') }}
                <v-avatar
                    color="gray"
                    size="40"
                >
                  <span class="black--text">{{ facilityUsersCounters.inactive }}</span>
                </v-avatar>
              </div>
              <!--              DECEASED-->
              <div class="d-flex flex-column align-center font-weight-bold font-size12 black--text px-3">
                {{ $t('deceased') }}
                <v-avatar
                    color="#000"
                    size="40"
                >
                  <span class="white--text">{{ facilityUsersCounters.deceased }}</span>
                </v-avatar>
              </div>
              </div>
              <div class="d-flex full-width flex-column">
              <v-progress-linear
                  v-if="facilityClicked"
                  class="font-size10 mx-2 px-2 my-1 font-weight-bold"
                  :size="65"
                  :width="2"
                  height="15"
                  striped
                  :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99454 : 0"
                  :color="getHospitalSummary(this.clickedFacilityId)?.percent99454 >= 100 ? 'green' : 'primary'"
              >
                99454 {{ getHospitalSummary(this.clickedFacilityId)?.percent99454 || '0' }}%
              </v-progress-linear>
              <v-progress-linear
                  v-if="facilityClicked"
                  class="font-size11 mx-2 px-2 my-1 font-weight-bold"
                  :size="65"
                  :width="2"
                  height="15"
                  striped
                  :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent99457 : 0"
                  :color="getHospitalSummary(this.clickedFacilityId)?.percent99457 >= 100 ? 'green' : 'primary'"
              >
                99457  {{ getHospitalSummary(this.clickedFacilityId)?.percent99457 || '0' }}%
              </v-progress-linear>
              <v-progress-linear
                  v-if="facilityClicked"
                  class="font-size11 mx-2 my-1 font-weight-bold"
                  :size="65"
                  :width="2"
                  height="15"
                  striped
                  :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId).percent994581st : 0"
                  :color="getHospitalSummary(this.clickedFacilityId)?.percent994581st >= 100 ? 'green' : 'primary'"
              >
                99458 1st {{ getHospitalSummary(this.clickedFacilityId)?.percent994581st || '0' }}%
              </v-progress-linear>
              <v-progress-linear
                  v-if="facilityClicked"
                  class="font-size11 mx-2 my-1 font-weight-bold"
                  :size="65"
                  :width="2"
                  height="15"
                  striped
                  :value="getHospitalSummary(clickedFacilityId) ? getHospitalSummary(clickedFacilityId)?.percent994582nd : 0"
                  :color="getHospitalSummary(this.clickedFacilityId)?.percent994582nd >= 100 ? 'green' : 'primary'"
              >
                99458 2nd {{ getHospitalSummary(this.clickedFacilityId)?.percent994582nd || '0' }}%
              </v-progress-linear>
              </div>
            </div>
            <v-divider class="mt-1"></v-divider>
            <div>

              <!--              <v-data-table-->
              <!--                  :no-data-text="$t('no-data-available')"-->
              <!--                  :loading-text="$t('loading')"-->
              <!--                  dense-->
              <!--                  :items="adminMedicalInstitutions?.medicalInsititutionPatientsData?.hospitalsList"-->
              <!--                  :footer-props="footerProps"-->
              <!--                  :items-per-page="-1"-->
              <!--                  fixed-header-->
              <!--                  :height="pageHeight(-200)"-->
              <!--                  group-by="adminMedicalInstitutions?.medicalInsititutionPatientsData?.hospitalsList"-->
              <!--                  item-key="hospitalId"-->
              <!--              >-->
              <!--                <template v-slot:[`group.header`]="{ headers, items, toggle, isOpen, group }">-->
              <!--                  <tr v-for="(item, index) in items" :key="index">-->
              <!--                      <td-->
              <!--                          @click="toggle"-->
              <!--                          class="elevation-1 px-1 py-0 white-background"-->
              <!--                          :colspan="10"-->
              <!--                      >-->
              <!--                        <v-col cols="1" class="float-left mr-2">-->
              <!--                          <v-btn small icon :ref="group" :data-open="isOpen" class="color-black">-->
              <!--                            <v-icon small v-if="isOpen">mdi-arrow-down</v-icon>-->
              <!--                            <v-icon v-else small>mdi-arrow-right</v-icon>-->
              <!--                          </v-btn>-->
              <!--                        </v-col>-->
              <!--                        <v-col cols="6" class="d-flex text-left py-0 ml-n5">-->
              <!--                        <span class="font-weight-bold ml-1 font-size12 mt-3">-->
              <!--                          {{ item.hospitalName }}-->
              <!--                        </span>-->
              <!--                        </v-col>-->
              <!--                        <v-col>-->
              <!--                          <v-tooltip bottom color="primary2">-->
              <!--                            <template v-slot:activator="{ on, attrs }">-->
              <!--                              <v-icon-->
              <!--                                  v-bind="attrs"-->
              <!--                                  v-on="on"-->
              <!--                                  small-->
              <!--                                  color="blue"-->
              <!--                                  class="float-right mt-2"-->
              <!--                              >-->
              <!--                                mdi-information-outline-->
              <!--                              </v-icon>-->
              <!--                            </template>-->
              <!--                            <span>{{ $t('view-facility-details') }}</span>-->
              <!--                          </v-tooltip>-->
              <!--                        </v-col>-->
              <!--                      </td>-->
              <!--                  </tr>-->
              <!--                </template>-->
              <!--              </v-data-table>-->

              <!-- Statistics Cards -->
              <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
              <!--                  <v-card height="170">-->
              <!--                      <div class="font-size16 font-weight-bold pt-1">Week 1</div>-->
              <!--                    <div>-->
              <!--                      <v-progress-circular-->
              <!--                          :rotate="360"-->
              <!--                          :size="70"-->
              <!--                          :width="5"-->
              <!--                          :value="100"-->
              <!--                          color="green"-->
              <!--                          class="mt-3"-->
              <!--                      >-->
              <!--                        100-->
              <!--                      </v-progress-circular>-->
              <!--                    </div>-->

              <!--                    <div class="font-size14 font-weight-bold mt-1">Excellent Measurement</div>-->
              <!--                    <div class="d-flex align-center  justify-center">-->
              <!--                      <span class="font-size12">2024-06-01 /</span>-->
              <!--                      <span class="font-size12 ml-1">2024-06-07 </span>-->
              <!--                    </div>-->
              <!--                  </v-card>-->
              <!--                </v-col>-->
              <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
              <!--                  <v-card height="170">-->
              <!--                    <div class="font-size16 font-weight-bold pt-1">Week 2</div>-->
              <!--                      <div>-->
              <!--                        <v-progress-circular-->
              <!--                            :rotate="360"-->
              <!--                            :size="70"-->
              <!--                            :width="5"-->
              <!--                            :value="85"-->
              <!--                            color="blue"-->
              <!--                            class="mt-3"-->
              <!--                        >-->
              <!--                          85-->
              <!--                        </v-progress-circular>-->
              <!--                      </div>-->
              <!--                    <div class="font-size14 font-weight-bold mt-1">Average Measurements </div>-->
              <!--                    <div class="d-flex align-center  justify-center">-->
              <!--                      <span class="font-size12">2024-06-08 /</span>-->
              <!--                      <span class="font-size12 ml-1">2024-06-15 </span>-->
              <!--                    </div>-->
              <!--                  </v-card>-->
              <!--                </v-col>-->

              <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
              <!--                  <v-card height="170">-->
              <!--                    <div class="font-size16 font-weight-bold pt-1">Week 3</div>-->
              <!--                    <div>-->
              <!--                      <v-progress-circular-->
              <!--                          :rotate="360"-->
              <!--                          :size="70"-->
              <!--                          :width="5"-->
              <!--                          :value="50"-->
              <!--                          color="orange"-->
              <!--                          class="mt-3"-->
              <!--                      >-->
              <!--                        50-->
              <!--                      </v-progress-circular>-->
              <!--                    </div>-->
              <!--                    <div class="font-size14 font-weight-bold mt-1"> Satisfactory Measruement</div>-->
              <!--                    <div class="d-flex align-center  justify-center">-->
              <!--                      <span class="font-size12">2024-06-16 /</span>-->
              <!--                      <span class="font-size12 ml-1">2024-06-23 </span>-->
              <!--                    </div>-->
              <!--                  </v-card>-->
              <!--                </v-col>-->

              <!--                <v-col cols="12" sm="6" md="12" xl="3">-->
              <!--                  <v-card height="170">-->
              <!--                    <div class="font-size16 font-weight-bold pt-1">Week 4</div>-->
              <!--                    <div>-->
              <!--                      <v-progress-circular-->
              <!--                          :rotate="360"-->
              <!--                          :size="70"-->
              <!--                          :width="5"-->
              <!--                          :value="20"-->
              <!--                          color="red"-->
              <!--                          class="mt-2"-->
              <!--                      >-->
              <!--                        20-->
              <!--                      </v-progress-circular>-->
              <!--                    </div>-->
              <!--                    <div class="font-size14 font-weight-bold mt-2">Bad Performance </div>-->
              <!--                    <div class="d-flex align-center  justify-center">-->
              <!--                      <span class="font-size12">2024-06-24 /</span>-->
              <!--                      <span class="font-size12 ml-1">2024-06-30 </span>-->
              <!--                    </div>-->
              <!--                  </v-card>-->
              <!--                </v-col>-->
            </div>
          </v-card-text>
          <v-col>
            <v-card>
              <div class="d-flex align-center pa-2 my-n5">
                <div class="font-size16 font-weight-bold pt-1 mr-3">{{$t('month')}}</div>
                <v-progress-linear
                    class="white--text"
                    striped
                    :value="getOverAllProgressFor(clickedFacilityId).toFixed(1)"
                    :color="progressColor"
                    height="25">
                  {{ getOverAllProgressFor(clickedFacilityId).toFixed(1) }} %
                </v-progress-linear>
              </div>

            </v-card>
<!--            <div class="d-flex justify-space-between px-12">-->
<!--              &lt;!&ndash;              ACTIVE&ndash;&gt;-->
<!--              <div class="d-flex flex-column align-center font-weight-bold black&#45;&#45;text">-->
<!--                {{ $t('active') }}-->
<!--                <v-avatar-->
<!--                    color="#09CB09"-->
<!--                    size="40">-->
<!--                  <span class="white&#45;&#45;text">{{ facilityUsersCounters.active }}</span>-->
<!--                </v-avatar>-->
<!--              </div>-->
<!--              &lt;!&ndash;              INACTIVE&ndash;&gt;-->
<!--              <div class="d-flex flex-column align-center font-weight-bold black&#45;&#45;text">-->
<!--                {{ $t('inactive') }}-->
<!--                <v-avatar-->
<!--                    color="gray"-->
<!--                    size="40"-->
<!--                >-->
<!--                  <span class="black&#45;&#45;text">{{ facilityUsersCounters.inactive }}</span>-->
<!--                </v-avatar>-->
<!--              </div>-->
<!--              &lt;!&ndash;              DECEASED&ndash;&gt;-->
<!--              <div class="d-flex flex-column align-center font-weight-bold black&#45;&#45;text">-->
<!--                {{ $t('deceased') }}-->
<!--                <v-avatar-->
<!--                    color="#000"-->
<!--                    size="40"-->
<!--                >-->
<!--                  <span class="white&#45;&#45;text">{{ facilityUsersCounters.deceased }}</span>-->
<!--                </v-avatar>-->
<!--              </div>-->
<!--            </div>-->
          </v-col>
          <v-card-text>
            <div style="display: flex; flex-direction: column;">
            <Graph ref="graphFacility" type="area" height="178vh" class="my-n4" :options="chartOptionsFacility" :series="graphSeriesFacility"></Graph>
            </div>
          </v-card-text>
        </v-card>
        <v-card elevation="5" class="overflow-hidden my-n3"  height="42vh">
          <div class="d-flex align-center elevation-3 py-1" >
            <v-img class="ml-1" :src="infoBlack" max-height="21" max-width="22"></v-img>
            <div class="text-left font-weight-bold font-size15 ml-1 ">{{$t('organization-revenue')}} : {{ selectedOrganizationName }}</div>
          </div>
          <v-divider></v-divider>
          <v-card-text>
<!--            <v-row>-->
<!--              <v-col cols="6">-->
<!--                <v-data-table-->
<!--                    :headers="headers"-->
<!--                    :items="invoicesDataFacility"-->
<!--                    class="elevation-3 mb-3"-->
<!--                    :items-per-page="-1"-->
<!--                    height="340"-->
<!--                >-->
<!--                  <template v-slot:item="{ item }">-->
<!--                    <tr>-->
<!--                      <td class="text-left font-weight-medium font-size12" style="height:10px;">-->
<!--                        {{ formatYearMonthV2(item.yearMonth) }}-->
<!--                      </td>-->
<!--                      <td class="text-left font-weight-medium font-size12" style="height:10px;">-->
<!--                        {{ item.invoiceNumber }}-->
<!--                      </td>-->
<!--                      <td class="text-right pr-4 font-weight-medium font-size12" style="height:10px;">-->
<!--                        {{ formatCurrency(item.totalAmount) }}-->
<!--                      </td>-->
<!--                    </tr>-->
<!--                  </template>-->
<!--                </v-data-table>-->
<!--              </v-col>-->
<!--                <v-col cols="6">-->
                <div style="display: flex; flex-direction: column;">
                  <Graph ref="graphOrganization" type="area" height="280vh" :options="chartOptionsOrganization" :series="graphSeriesOrganization"></Graph>
                </div>
<!--                </v-col>-->
<!--            </v-row>-->
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { billBlack, organizationsBlack, info } from '@/assets';
import Graph from 'vue-apexcharts';

export default {
  components: {
    Graph,
  },
  data () {
    return {
      graphSeriesWellnessmetric: [{
        name: this.$t('revenue'),
        data: [],
      }],
      chartOptionsWellnessmetric: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            // Format the value as currency
            return `$${value.toFixed(0)}`;
          },
        },
        stroke: {
          curve: 'smooth',
        },

        title: {
          text: '',
          align: 'left',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function (value) {
              // Create a new Date object from the timestamp
              const date = new Date(value);
              // Options to format the date as "MMM yyyy"
              const options = { year: 'numeric', month: 'short' };
              // Return formatted date string
              return date.toLocaleDateString('en-US', options);
            },
          },
        },
        yaxis: {
          opposite: false,
          min: 0,
          labels: {
            formatter: function (value) {
              // Format the value as currency
              return `$${value.toFixed(0)}`;
            },
          },
        },
        legend: {
          horizontalAlign: 'left',
        },
      },
      graphSeriesOrganization: [{
        name: this.$t('revenue'),
        data: [],
      }],
      chartOptionsOrganization: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            // Format the value as currency
            return `$${value.toFixed(0)}`;
          },
        },
        stroke: {
          curve: 'smooth',
        },

        title: {
          text: '',
          align: 'left',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function (value) {
              // Create a new Date object from the timestamp
              const date = new Date(value);
              // Options to format the date as "MMM yyyy"
              const options = { year: 'numeric', month: 'short' };
              // Return formatted date string
              return date.toLocaleDateString('en-US', options);
            },
          },
        },
        yaxis: {
          opposite: false,
          min: 0,
          labels: {
            formatter: function (value) {
              // Format the value as currency
              return `$${value.toFixed(0)}`;
            },
          },
        },
        legend: {
          horizontalAlign: 'left',
        },
      },
      graphSeriesFacility: [{
        name: this.$t('revenue'),
        data: [],
      }],
      chartOptionsFacility: {
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            // Format the value as currency
            return `$${value.toFixed(0)}`;
          },
        },
        stroke: {
          curve: 'smooth',
        },

        title: {
          text: '',
          align: 'left',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function (value) {
              // Create a new Date object from the timestamp
              const date = new Date(value);
              // Options to format the date as "MMM yyyy"
              const options = { year: 'numeric', month: 'short' };
              // Return formatted date string
              return date.toLocaleDateString('en-US', options);
            },
          },
        },
        yaxis: {
          opposite: false,
          min: 0,
          labels: {
            formatter: function (value) {
              // Format the value as currency
              return `$${value.toFixed(0)}`;
            },
          },
        },
        legend: {
          horizontalAlign: 'left',
        },
      },
      headers: [
        { text: this.$t('month'), align: 'start', sortable: false, value: 'name' },
        { text: this.$t('header-invoice-number'), value: 'Invoice' },
        { text: '', align: 'end' },
      ],
      billingsNew: billBlack,
      infoBlack: info,
      loader: false,
      organizationsBlack: organizationsBlack,
      facilitiesTableLoader: false,
      hospitalName: null,
      hospitalData: [],
      selectedMedicalInstitution: null,
      expanded: [],
      singleExpand: true,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      billingSummary: {
        cpt99453: 0,
        cpt99454: 0,
        cpt99457: 0,
        cpt994581st: 0,
        cpt994582nd: 0,
      },
      clickedFacilityId: null,
      medicalInstitutionHeaders: [
        { text: this.$t('Organization'), value: 'medicalInstitutionName', align: 'start', sortable: false, class: 'pl-3' },
        { text: '', align: 'start', value: '', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },
        { text: '', align: 'start', value: '', sortable: false },
        { text: this.$t('last-invoice'), align: 'end', value: '', sortable: false },

      ],
      facilityClicked: true,
      facilityUsersCounters: {
        active: 0,
        inactive: 0,
        deceased: 0,
      },
      selectedOrganizationName: '',
      insideHeaders: [
        { text: this.$t('facility'), sortable: true, class: 'primary', align: 'start' },
        { text: '', align: 'start', value: '', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      selectedMonthAndYear: (state) => state.filterbar.systemAdminDate,
      billingsFromRepo: state => state.billings.billingSummaryForMonth,
      billingReport: (state) => state.admin.billingReport,
    }),
    ...mapGetters({
      pageHeight: 'pageHeight',
      roleName: 'authentication/getRole',
      appBarSearch: 'filterbar/getSearch',
    }),
    billingReportForDataTable () {
      return this.billingReport?.medicalInsititutionPatientsData || [];
    },
    progressColor () {
      const progress = this.getOverAllProgressFor(this.clickedFacilityId).toFixed(1);
      if (progress < 75) {
        return 'red'; // Color for progress less than 50
      } else if (progress >= 75 && progress < 90) {
        return 'orange'; // Color for progress between 50 and 75
      } else {
        return 'green'; // Color for progress 75 and above
      }
    },
  },
  created () {
    this.getBillingReport();
  },
  beforeDestroy () {
    this.$store.dispatch('filterbar/enableDatePicker', false);
  },
  watch: {
    selectedMonthAndYear (val) {
      if (val) {
        this.getbillingsummaryforallpatientsformonth();
        this.toggleHeadersV2();
      }
    },
  },
  methods: {
    toggleHeadersV2 () {
      const table = this.$refs.table;
      if (table) {
        const keys = Object.keys(table.$vnode.componentInstance.openCache);
        keys.forEach((x) => {
          table.$vnode.componentInstance.openCache[x] = false;
        });
      }
    },
    getLastInvoiceAmount (invoiceData) {
      var currency = 0;
      if (invoiceData === null || invoiceData === undefined) {
        return currency.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
      if (invoiceData.length === 0) {
        return currency.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      } else {
        return invoiceData[invoiceData.length - 1].grandTotal.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      }
    },
    selectedOrganization (val, isOpen) {
        // isOpen working with inverted logic
        if (!isOpen) {
          this.selectedMedicalInstitution = val.medicalInstitutionId;
          this.getbillingsummaryforallpatientsformonth(val.medicalInstitutionId);
          this.populateOrganizationGraphData(val.medicalInstitutionId);
          if (this.selectedMedicalInstitution) {
            this.selectedOrganizationName = val.medicalInstitutionName;
          } else {
            this.selectedOrganizationName = '';
          }
        }
        this.$store.dispatch('filterbar/enableDatePicker', true);
    },
    getBillingReport () {
      const body = {
        filterCase: 'ALL',
      };
      this.loader = true;
      this.$store.dispatch('admin/getBillingReport', body).then(res => {
        this.loader = false;
        this.populateWellnessmetricGraphData();
        this.toggleHeadersV2();
      });
    },
    populateWellnessmetricGraphData () {
      const allInvoices = this.billingReport.medicalInsititutionPatientsData.flatMap(institution => institution.invoiceReadDtoForReporting);

      const newData = this.wellnessRevenue(this.organizationRevenuesGraph(allInvoices));
      this.graphSeriesWellnessmetric = [{ name: this.$t('revenue'), data: newData }];
      this.$refs.graphWellnessmetric.updateSeries(this.graphSeriesWellnessmetric);
    },
    populateOrganizationGraphData (val) {
      const newData = this.organizationRevenuesGraph(this.billingReport?.medicalInsititutionPatientsData.find(item => item.medicalInstitutionId === val).invoiceReadDtoForReporting);
      this.graphSeriesOrganization = [{ name: this.$t('revenue'), data: newData }];
      this.$refs.graphOrganization.updateSeries(this.graphSeriesOrganization);
    },
    populateFacilityGraphData () {
      const hospital = this.billingReport?.medicalInsititutionPatientsData.find(item => item.medicalInstitutionId === this.selectedMedicalInstitution).hospitalsList.find(item => item.hospitalId === this.clickedFacilityId);
      this.graphSeriesFacility = [{ name: this.$t('revenue'), data: this.facilityRevenuesGraph(hospital.hospitalRevenues) }];
      this.$refs.graphFacility.updateSeries(this.graphSeriesFacility);
    },
    facilityRevenuesGraph (val) {
      if (!val) {
        return [];
      }
      return val.map(invoice => [invoice.yearMonth, invoice.totalAmount]);
    },
    wellnessRevenue (data) {
      // Object to store the aggregated sums
      const aggregatedSums = {};
      // Process each entry in the data
      data.forEach(([dateStr, value]) => {
        // Extract year and month from the date string
        const yearMonth = dateStr.substring(0, 7);

        // Aggregate the value by year and month
        if (aggregatedSums[yearMonth]) {
          aggregatedSums[yearMonth] += value;
        } else {
          aggregatedSums[yearMonth] = value;
        }
      });
      // Convert the aggregated sums to an array of arrays
      const result = Object.entries(aggregatedSums).map(([yearMonth, total]) => [yearMonth, total]);
      result.sort((a, b) => new Date(a[0]) - new Date(b[0]));
      return result;
    },
    organizationRevenuesGraph (val) {
      if (!val) {
        return [];
      }
      var a = val.map(invoice => [invoice.invoiceDate, invoice.grandTotal]);
      return a;
    },
    async handleFacilityClick (val) {
      this.clickedFacilityId = val.hospitalId;
      const hospitalInfos = this.billingReport?.medicalInsititutionPatientsData.find(item => item.medicalInstitutionId === this.selectedMedicalInstitution).hospitalsList.find(item => item.hospitalId === val.hospitalId);
      this.facilityUsersCounters.active = hospitalInfos.patientsList.filter(item => item.status === 1).length;
      this.facilityUsersCounters.inactive = hospitalInfos.patientsList.filter(item => (item.status !== 1 && item.status !== 99)).length;
      this.facilityUsersCounters.deceased = hospitalInfos.patientsList.filter(item => item.status === 99).length;
      this.hospitalName = val.hospitalName;
      this.facilityClicked = true;
      this.populateFacilityGraphData();
    },
    async expand (item) {
      if (item === this.expanded[0]) this.expanded = [];
      else this.expanded = [item];
    },
    getHospitalSummary (selectedHospitalId) {
      let hospitalId = null;
      if (selectedHospitalId === null) {
        hospitalId = localStorage.getItem('selectedHospitalAssignedId');
      } else {
        hospitalId = selectedHospitalId;
      }

      if (!hospitalId) {
        return null;
      }

      if (hospitalId in this.hospitalData) {
        return this.hospitalData[hospitalId];
      } else {
        return null;
      }
    },
    getOverAllProgressFor (selectedHospitalId) {
      var result = ((this.getHospitalSummary(selectedHospitalId)?.percent99454 * (1 / 4)) + (this.getHospitalSummary(selectedHospitalId)?.percent99457 * (1 / 4)) +
          (this.getHospitalSummary(selectedHospitalId)?.percent994581st * (1 / 4)) + (this.getHospitalSummary(selectedHospitalId)?.percent994582nd * (1 / 4)));
      if (isNaN(result)) result = 0;

      return result;
    },
    aggregateData () {
      const aggregatedData = {};

      for (const entry of this.billingsFromRepo) {
        if (entry.id === 0) { break; }
        const hospitalId = entry.hospitalData.id;

        if (!(hospitalId in aggregatedData)) {
          aggregatedData[hospitalId] = {
            counter: 0,
            counter99453: 0,
            percent99453: 0.0,
            counter99454: 0,
            percent99454: 0.0,
            counter99457: 0,
            percent99457: 0.0,
            counter994581st: 0,
            percent994581st: 0.0,
            counter994582nd: 0,
            percent994582nd: 0.0,
          };
        }

        aggregatedData[hospitalId].counter += 1;

        if (entry.cptCode99453?.status === 1) {
          aggregatedData[hospitalId].counter99453 += 1;
        }
        if (entry.cptCode99454?.status === 1) {
          aggregatedData[hospitalId].counter99454 += 1;
        }
        if (entry.cptCode99457?.status === 1) {
          aggregatedData[hospitalId].counter99457 += 1;
        }
        if (entry.cptCode99458First?.status === 1) {
          aggregatedData[hospitalId].counter994581st += 1;
        }
        if (entry.cptCode99458Second?.status === 1) {
          aggregatedData[hospitalId].counter994582nd += 1;
        }
      }

      for (const hospitalId in aggregatedData) {
        const totalCounter = aggregatedData[hospitalId].counter;
        if (totalCounter > 0) {
          aggregatedData[hospitalId].percent99453 = ((aggregatedData[hospitalId].counter99453 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99454 = ((aggregatedData[hospitalId].counter99454 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent99457 = ((aggregatedData[hospitalId].counter99457 / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994581st = ((aggregatedData[hospitalId].counter994581st / totalCounter) * 100).toFixed(1);
          aggregatedData[hospitalId].percent994582nd = ((aggregatedData[hospitalId].counter994582nd / totalCounter) * 100).toFixed(1);
        }
      }

      var patientNumber = this.billingsFromRepo.length;

      this.billingSummary.cpt99453 = 0;
      this.billingSummary.cpt99454 = 0;
      this.billingSummary.cpt99457 = 0;
      this.billingSummary.cpt994581st = 0;
      this.billingSummary.cpt994582nd = 0;

      if (patientNumber > 0) {
        this.billingsFromRepo.forEach((patient, index) => {
          if (patient.cptCode99453?.status === 1) {
            this.billingSummary.cpt99453++;
          }
          if (patient.cptCode99454?.status === 1) {
            this.billingSummary.cpt99454++;
          }
          if (patient.cptCode99457?.status === 1) {
            this.billingSummary.cpt99457++;
          }
          if (patient.cptCode99458First?.status === 1) {
            this.billingSummary.cpt994581st++;
          }
          if (patient.cptCode99458Second?.status === 1) {
            this.billingSummary.cpt994582nd++;
          }

          let percent99454 = 0;
          let totalMeasurements99454 = 0;
          let measurement99454IsBillable = false;
          let measurement99457IsBillable = false;
          let measurement994581stIsBillable = false;
          let measurement994582ndIsBillable = false;
          if (patient.cptCode99454 !== null) {
            if (patient.cptCode99454.totalMeasurements < 16) {
              percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
            } else {
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
              percent99454 = 100;
              measurement99454IsBillable = true;
            }
          }

          const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

          let percent99457 = 0;
          let percent994581st = 0;
          let percent994582nd = 0;

          let seconds99457 = 0;
          let seconds994581st = 0;
          let seconds994582nd = 0;

          if (totalSec < 1200) {
            seconds99457 = totalSec;
            percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

            seconds994581st = 0;
            percent994581st = 0;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 1200 && totalSec < 2400) {
            seconds99457 = 1200;
            percent99457 = 100;
            measurement99457IsBillable = true;

            seconds994581st = totalSec - 1200;
            percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 2400 && totalSec < 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;
            measurement994581stIsBillable = true;

            seconds994582nd = totalSec - 2400;
            percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
          }

          if (totalSec >= 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;

            seconds994582nd = totalSec - 2400;
            percent994582nd = 100;
            measurement994582ndIsBillable = true;
          }
          const myBillings = {
            totalMeasurements99454: totalMeasurements99454,
            percent99454: percent99454,
            percent99457: percent99457,
            percent994581st: percent994581st,
            percent994582nd: percent994582nd,
            seconds99457: seconds99457,
            seconds994581st: seconds994581st,
            seconds994582nd: seconds994582nd,
            m99454IsBillable: measurement99454IsBillable,
            m99457IsBillable: measurement99457IsBillable,
            m994581stIsBillable: measurement994581stIsBillable,
            m994582ndIsBillable: measurement994582ndIsBillable,
          };
          patient.myBillings = myBillings;
        });
        this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
      }
      this.hospitalData = aggregatedData;
    },
    getbillingsummaryforallpatientsformonth (val) {
      const savedOrganizationId = val || this.selectedMedicalInstitution;
      let year, month;
      if (this.selectedMonthAndYear) {
        [year, month] = this.selectedMonthAndYear.split('-');
      } else {
        const today = new Date();
        year = today.getFullYear();
        month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      }

      const headers = {
        organizationId: savedOrganizationId,
        month: month,
        year: year,
      };
      this.facilitiesTableLoader = true; // Hide loader after successful API call

      this.$store.dispatch('billings/getbillingsummaryforallpatientsformonth', headers)
          .then(() => {
            // Handle successful response here
            this.facilitiesTableLoader = false; // Hide loader after successful API call
          });
      var patientNumber = this.billingsFromRepo.length;
      this.billingSummary.cpt99453 = 0;
      this.billingSummary.cpt99454 = 0;
      this.billingSummary.cpt99457 = 0;
      this.billingSummary.cpt994581st = 0;
      this.billingSummary.cpt994582nd = 0;
      if (patientNumber > 0) {
        this.billingsFromRepo.forEach((patient, index) => {
          if (patient.cptCode99453?.status === 1) {
            this.billingSummary.cpt99453++;
          }
          if (patient.cptCode99454?.status === 1) {
            this.billingSummary.cpt99454++;
          }
          if (patient.cptCode99457?.status === 1) {
            this.billingSummary.cpt99457++;
          }
          if (patient.cptCode99458First?.status === 1) {
            this.billingSummary.cpt994581st++;
          }
          if (patient.cptCode99458Second?.status === 1) {
            this.billingSummary.cpt994582nd++;
          }

          let percent99454 = 0;
          let totalMeasurements99454 = 0;
          let measurement99454IsBillable = false;
          let measurement99457IsBillable = false;
          let measurement994581stIsBillable = false;
          let measurement994582ndIsBillable = false;
          if (patient.cptCode99454 !== null) {
            if (patient.cptCode99454.totalMeasurements < 16) {
              percent99454 = ((patient.cptCode99454.totalMeasurements / 16) * 100).toFixed(0);
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
            } else {
              totalMeasurements99454 = patient.cptCode99454.totalMeasurements;
              percent99454 = 100;
              measurement99454IsBillable = true;
            }
          }

          const totalSec = patient.cptCode99457?.totalIntervalInSeconds + patient.cptCode99458First?.totalIntervalInSeconds + patient.cptCode99458Second?.totalIntervalInSeconds;

          let percent99457 = 0;
          let percent994581st = 0;
          let percent994582nd = 0;

          let seconds99457 = 0;
          let seconds994581st = 0;
          let seconds994582nd = 0;

          if (totalSec < 1200) {
            seconds99457 = totalSec;
            percent99457 = (((totalSec / 1200) * 100) < 100) ? ((totalSec / 1200) * 100).toFixed(1) : 100;

            seconds994581st = 0;
            percent994581st = 0;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 1200 && totalSec < 2400) {
            seconds99457 = 1200;
            percent99457 = 100;
            measurement99457IsBillable = true;

            seconds994581st = totalSec - 1200;
            percent994581st = (((seconds994581st / 1200) * 100) < 100) ? ((seconds994581st / 1200) * 100).toFixed(0) : 100;

            seconds994582nd = 0;
            percent994582nd = 0;
          }

          if (totalSec >= 2400 && totalSec < 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;
            measurement994581stIsBillable = true;

            seconds994582nd = totalSec - 2400;
            percent994582nd = (((seconds994582nd / 1200) * 100) < 100) ? ((seconds994582nd / 1200) * 100).toFixed(0) : 100;
          }

          if (totalSec >= 3600) {
            seconds99457 = 1200;
            percent99457 = 100;

            seconds994581st = 1200;
            percent994581st = 100;

            seconds994582nd = totalSec - 2400;
            percent994582nd = 100;
            measurement994582ndIsBillable = true;
          }

          const myBillings = {
            totalMeasurements99454: totalMeasurements99454,
            percent99454: percent99454,
            percent99457: percent99457,
            percent994581st: percent994581st,
            percent994582nd: percent994582nd,
            seconds99457: seconds99457,
            seconds994581st: seconds994581st,
            seconds994582nd: seconds994582nd,
            m99454IsBillable: measurement99454IsBillable,
            m99457IsBillable: measurement99457IsBillable,
            m994581stIsBillable: measurement994581stIsBillable,
            m994582ndIsBillable: measurement994582ndIsBillable,
          };
          patient.myBillings = myBillings;
        });
        this.billingSummary.cpt99453 = ((this.billingSummary.cpt99453 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99454 = ((this.billingSummary.cpt99454 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt99457 = ((this.billingSummary.cpt99457 / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994581st = ((this.billingSummary.cpt994581st / patientNumber) * 100).toFixed(0);
        this.billingSummary.cpt994582nd = ((this.billingSummary.cpt994582nd / patientNumber) * 100).toFixed(0);
      }
      this.aggregateData();
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
}
</style>
